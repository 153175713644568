import React                 from 'react';
import { Flex, Box, Center } from '@chakra-ui/react';
import Img                   from 'gatsby-image';
import { graphql }           from 'gatsby';

import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import HeaderMedia   from '@interness/theme-default/src/components/HeaderMedia';
import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import { findMedia } from '@interness/web-core/src/components/_helpers';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Separator     from '@interness/web-core/src/components/structure/Separator/Separator';


const ServicePage = (props) => {
  return (
    <>
      <HeaderMedia id="service-3"/>
      <Spacer/>
      <Wrapper>
        <Heading subtitle='Unser Service für Sie'>Service</Heading>
        <Spacer/>
        <Flex flexWrap='wrap'>
          <Box width={['100%', '100%', '33%', '33%']} textAlign='justify'>
            <h3>Service</h3>
            <p>Dank einer neuen elektronischen Bestands-überwachung, sind wir - als auch unsere Kunden - jederzeit in der Lage, den vorhandenen Lagerbestand an Transport- und Lagerpaletten zu überprüfen und benötigte Mengen automatisch in die Benachschubung zu bringen.
              Mit einer intelligenten Wechselbrückensteuerung ist eine effiziente, sichere und aufwandsarme Verfolgung sowie Einsatzplanung der Ladungs-träger möglich.</p>
          </Box>
          <Box width={['100%', '100%', '33%', '33%']} px={8}>
            <h3>VMI</h3>
            <ul>
              <li>Fehlerreduktion durch Wegfall manueller Tätigkeiten</li>
              <li>Reduzierung der Transport und Bestandskosten</li>
              <li>Risiokominimierung (Abrechnung und Faktuierung erst nach Entnahme)</li>
              <li>Standardisierte Zahlungsabwicklung</li>
              <li>Transportoptimierung</li>
              <li>Senkung des Dispositionsrisikos</li>
              <li>Transparenz</li>
            </ul>
          </Box>
          <Box width={['100%', '100%', '33%', '33%']}>
            <h3>Konsilager</h3>
            <ul>
              <li>Hohe Verfügbarkeit und Versorgungssicherheit durch lokale Nähe</li>
              <li>Eliminierung der Transaktionen durch Sammelrechnungen</li>
              <li>Optimierung von Beständen</li>
              <li>Fehlerreduktion in der Auftragserfassung</li>
              <li>Reduzierung der Falschlieferungen und von Fehlerfolgekosten</li>
              <li>Erhöhung der Prognosequalität</li>
              <li>Verbesserung der Lieferbereitschaft</li>
            </ul>
          </Box>
        </Flex>
        <Separator />
        <Center>
          <Box width='100%'>
            <Img fluid={findMedia(props.data.collection.images, 'konsilager-uebersicht').childImageSharp.fluid}/>
          </Box>
        </Center>
      </Wrapper>
      <Spacer/>
    </>
  )
};

export default ServicePage;


export const query = graphql`
    query {
        collection: directusMediaCollection(name: {eq: "service"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxHeight: 700) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;